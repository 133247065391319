<template>
  <b-overlay
    :show="showOverlay"
    rounded="sm"
  >
    <div class="misc-wrapper">
      <!-- Brand logo-->
      <b-link
        class="brand-logo"
        style="top: 0rem !important;"
      >
        <div>
          <td style="vertical-align: middle;">
            <logo
              style="height: 50px; width: auto; margin-top: 1rem;"
            />
          </td>
          <td style="vertical-align: middle;">
            <h2
              class="brand-text text-primary"
              style="margin-top: 20px; margin-left: 0.5rem; text-align: center;"
            >
              {{ appName }}
            </h2>
          </td>
        </div>
      </b-link>
      <!-- /Brand logo-->

      <div class="misc-inner p-2 p-sm-3">
        <div class="w-100 text-center">
          <h2 class="mb-1">
            {{ $t('Connect to YRU Passport') }}
          </h2>
          <p class="mb-2">
            {{ $t('Please wait, the system is processing' ) }}
          </p>
          <b-alert
            v-if="alertMessage !== ''"
            show
            fade
            class="mb-0 mx-1"
            variant="danger"
          >
            <div class="alert-body">
              <span>{{ alertMessage }}</span>
            </div>
          </b-alert>
          <div>
            <b-button
              variant="primary"
              class="mt-2"
              @click="onBack"
            >
              <feather-icon icon="ChevronLeftIcon" />
              {{ $t('Back') }}
            </b-button>
          </div>
          <b-img
            fluid
            :src="imgUrl"
          />
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
/* eslint-disable global-require */
import {
  BLink, BImg,
  BOverlay,
  BAlert,
  BButton,
} from 'bootstrap-vue'
import Logo from '@/@core/layouts/components/Logo.vue'
import store from '@/store/index'
import { $themeConfig } from '@themeConfig'
import {
  Toast,
} from 'vant'

export default {
  components: {
    BLink,
    BImg,
    BOverlay,
    BAlert,
    BButton,
    Logo,
  },
  data() {
    return {
      showOverlay: false,
      downImg: require('@/assets/images/pages/not-authorized.svg'),
      skin: store.state.appConfig.layout.skin,
      alertMessage: '',
      appCode: $themeConfig.app.appCode,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/not-authorized-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
    routeParams() {
      return this.$route.params
    },
    lineOaId() {
      const { lineOaId } = store.state.lineLiff.line
      return lineOaId
    },
    profile() {
      return store.state.lineLiff.profile
    },
    routeQuery() {
      return this.$route.query
    },
    errorQuery() {
      if (this.routeQuery.error !== undefined) {
        return this.routeQuery.error
      }
      return ''
    },
    errorDescQuery() {
      if (this.routeQuery.error_description !== undefined) {
        return this.routeQuery.error_description
      }
      return ''
    },
  },
  mounted() {
    this.alertMessage = ''
    this.authorizeUrl()
  },
  methods: {
    onBack() {
      this.$router.push({ name: 'auth-login' })
    },
    authorizeUrl() {
      this.showOverlay = true
      store.dispatch('yruPassport/authorizeUrl', {})
        .then(response => {
          this.showOverlay = false
          if (response.status === true) {
            store.commit('yruPassport/UPDATE_AUTHORIZE', response.data)
            window.location.href = response.url
          }
        })
        .catch(error => {
          this.showOverlay = false
          const { response, message } = error
          if (response) {
            let errorMsg = this.$i18n.t(response?.data.error)
            if (errorMsg === '') {
              errorMsg = this.$i18n.t(response?.data.message)
            }
            Toast({
              message: errorMsg,
              icon: 'warning-o',
              position: 'middle',
              duration: 4000,
            })
          } else if (message) {
            Toast({
              message,
              icon: 'warning-o',
              position: 'middle',
              duration: 4000,
            })
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
